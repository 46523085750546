* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'GothamBold', 'GothamBook', 'GothamMedium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GothamBold';
  src: local('GothamBold'), url(./assets/fonts/GothamBold.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'), url(./assets/fonts/GothamBook.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamMedium';
  src: local('GothamMedium'), url(./assets/fonts/GothamMedium.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamLight';
  src: local('GothamLight'), url(./assets/fonts/GothamLight.ttf) format('truetype');
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    font-size: 1em;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.625em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  body {
    font-size: 1em;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.625em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 1em;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  body {
    font-size: 1em;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.25em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  body {
    font-size: 1em;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.25em;
  }
}